<template>
  <a-result status="404"
            title="404"
            :sub-title="$route.query.desc ||'此页面未找到'">
    <template #extra>
      <router-link :to="{name: $route.query.backRoute}">
        <a-button type="primary">返回</a-button>
      </router-link>
    </template>
  </a-result>
</template>
<script>
import { Result } from "ant-design-vue";

export default {
  components: {
    AResult: Result,
  },

  methods: {},
};
</script>
<style lang="less" scoped>
.ant-result {
  padding-top: 132px;
}
</style>